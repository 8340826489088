<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerUserForm">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>

<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <button mat-icon-button fxShow (click)="back()">
          <mat-icon aria-label="back">keyboard_backspace</mat-icon>
        </button>
      </h4>
    </div>
  </div>
  <div class="card-body">
    <div class="row" *ngIf="isLoaded">
      <div class="col-6">
        <overa-user-dropdown (selectedUserChange)="onSelectedUserChange($event)" [selectedUserId]="identityUserId"
          [isDisabled]="isNew ? false : true" [users]="users"></overa-user-dropdown>
      </div>
      <div class="col-6 container-autocompleteLaboratoryBranch">
        <p-autoComplete [(ngModel)]="selectedLaboratoryBranch" [suggestions]="filteredLaboratoryBranch"
          (completeMethod)="filterLaboratoryBranch($event)" field="name" [multiple]="true" [dropdown]="true" (onSelect)="checkIsSelected($event)" (onUnselect)="checkIsSelected($event)">
        </p-autoComplete>
      </div>
    </div>
    <div class="row">
      <div class="container-form-actions" [align]="'end'">
        <button mat-raised-button (click)="create()" [disabled]="!isloadedUser || !isloadedBranch" *ngIf="isNew">
          {{ "ACTION.CREATE" | translate }}
        </button>
        <button mat-raised-button (click)="edit()" *ngIf="!isNew">
          {{ "ACTION.EDIT" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
