import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import {
  LaboratoryBranchUsers,
  LaboratoryBranchUsersDataList,
} from "src/app/models/laboratory-branch.model";
import { LaboratoryBranchUserService } from "src/app/services/laboratory-branch-user.service";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";
import { UserFormComponent } from "../user-form/user-form.component";
import { Observable, forkJoin } from "rxjs";
import { OveraMessagesService } from "@overa/components";

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"],
  providers: [DialogService, ConfirmationService],
})
export class UsersListComponent implements OnInit, OnDestroy {
  columns: DataTableColumn[] = [];
  @Input()
  laboratoryId: string = "";
  showTable = false;
  listLaboratoryBranchUsers: LaboratoryBranchUsers[] = [];
  totalRecords = 0;
  listLaboratoryBranchUsersDataList: LaboratoryBranchUsersDataList[] = [];
  users: any[] = [];
  filteredUsers: any[] = [];
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private laboratoryBranchUserService: LaboratoryBranchUserService,
    private messagesService: OveraMessagesService
  ) {}
  ngOnInit() {
    this.accountService.getUsers().subscribe({
      next: (items) => {
        console.log("USERS", items);
        this.users = [...items];

        this.listLaboratoryBranchUsersDataList = [];
        this.listLaboratoryBranchUsersDataList = [
          ...this.listLaboratoryBranchUsersDataList,
        ];
        this.listLaboratoryBranchUsers = [];
        this.columns = this.createColumns();
        console.log(this.laboratoryId);
        this.spinner.show("spinnerUsersList");
        this.laboratoryBranchUserService.getOccupiedUsers().subscribe({
          next: (users) => {
            console.log("USERS", users);
            if (this.users.length > 0) {
              let copyUsers = [...this.users];
              this.filteredUsers = copyUsers.filter((user1) => {
                return !users.some((user) => user.identityUserId === user1.id);
              });
              console.log("Users", this.filteredUsers);
            }

            this.laboratoryBranchUserService
              .getLaboratoryBranchUsersToLaboratoryId(this.laboratoryId)
              .subscribe({
                next: (items) => {
                  console.log("GETRELATIONS", items);
                  if (items.length > 0) {
                    this.listLaboratoryBranchUsers = [...items];
                    if (this.users.length > 0) {
                      let copyUsers = [...this.filteredUsers];
                      this.filteredUsers = copyUsers.filter((user) => {
                        return !this.listLaboratoryBranchUsers.some(
                          (branchUser) => branchUser.identityUserId === user.id
                        );
                      });
                      console.log("filteredUsers", this.filteredUsers);
                    }
                    this.totalRecords = this.listLaboratoryBranchUsers.length;
                    this.createModelLaboratoryBranchUserDataListToFront();
                  }

                  let itemsTemp: LaboratoryBranchUsersDataList[] = [];

                  if (this.listLaboratoryBranchUsersDataList.length > 0) {
                    const goToDetail = (index: any) => {
                      if (
                        this.listLaboratoryBranchUsersDataList &&
                        this.listLaboratoryBranchUsersDataList[index]
                      ) {
                        this.openForm(
                          this.listLaboratoryBranchUsersDataList[index]
                        );
                      }
                    };
                    const deleteLaboratoryBranchUser = (index: any) => {
                      console.log(index);

                      if (
                        this.listLaboratoryBranchUsersDataList &&
                        this.listLaboratoryBranchUsersDataList[index]
                      ) {
                        this.delete(
                          this.listLaboratoryBranchUsersDataList[index]
                        );
                      }
                    };
                    this.listLaboratoryBranchUsersDataList.forEach(
                      (element: any) => {
                        element.selected = {
                          value: true, //TODO: add value from object
                          command: goToDetail,
                        };
                        element.deleteAction = {
                          value: true, //TODO: add value from object
                          command: deleteLaboratoryBranchUser,
                        };
                        console.log(element.name);
                        /*element.name =
                          element.name.toString() +
                          " " +
                          element.lastName.toString();*/
                        element.fullName =
                          element.name.toString() +
                          " " +
                          element.lastName.toString();
                        console.log(element.fullName);
                        itemsTemp.push(element);
                      }
                    );
                    this.listLaboratoryBranchUsersDataList = [...itemsTemp];

                    this.listLaboratoryBranchUsersDataList = [
                      ...this.listLaboratoryBranchUsersDataList,
                    ];
                    console.log(
                      "LISTADATALIST",
                      this.listLaboratoryBranchUsersDataList
                    );
                  } else {
                    this.listLaboratoryBranchUsersDataList = [];
                    this.listLaboratoryBranchUsers = [];
                    this.totalRecords = this.listLaboratoryBranchUsers.length;
                  }
                  this.showTable = true;
                  this.spinner.hide("spinnerUsersList");
                },
                error: (err) => {
                  console.error(err);
                  this.spinner.hide("spinnerUsersList");
                },
              });
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide("spinnerUsersList");
          },
        });
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
  createModelLaboratoryBranchUserDataListToFront() {
    const identityUserIdsUnicos = this.obtenerIdentityUserIdUnicos(
      this.listLaboratoryBranchUsers
    );
    console.log("IDENTITY", identityUserIdsUnicos);
    identityUserIdsUnicos.forEach((itemID) => {
      let laboratoryBranchUsersData = new LaboratoryBranchUsersDataList();
      let isFirstObject = true;
      laboratoryBranchUsersData.listLaboratoryBranchUsers = [];
      this.listLaboratoryBranchUsers.forEach((data) => {
        if (data.identityUserId === itemID) {
          if (isFirstObject) {
            laboratoryBranchUsersData.fullName=`${data.name} ${data.lastName}`;
            laboratoryBranchUsersData.name=data.name;
            laboratoryBranchUsersData.email = data.email;
            laboratoryBranchUsersData.lastName = data.lastName;
            laboratoryBranchUsersData.laboratoryId = data.laboratoryId;
            laboratoryBranchUsersData.identityUserId = data.identityUserId;
            isFirstObject = false;
          }
          laboratoryBranchUsersData.listLaboratoryBranchUsers?.push(data);
        }
      });
      if(!this.listLaboratoryBranchUsersDataList.some(existingData => existingData.identityUserId === laboratoryBranchUsersData.identityUserId)){
        this.listLaboratoryBranchUsersDataList.push(laboratoryBranchUsersData);
      }
    });
    this.listLaboratoryBranchUsersDataList = [
      ...this.listLaboratoryBranchUsersDataList,
    ];
  }
  obtenerIdentityUserIdUnicos(
    laboratoryBranchUsers: LaboratoryBranchUsers[]
  ): string[] {
    const identityUserIdsSet = new Set<string>(); // Utilizamos un Set para evitar duplicados

    laboratoryBranchUsers.forEach((usuario) => {
      if (usuario.identityUserId) {
        identityUserIdsSet.add(usuario.identityUserId);
      }
    });

    const identityUserIdsUnicos = Array.from(identityUserIdsSet);

    return identityUserIdsUnicos;
  }
  ngOnDestroy() {}
  createColumns(): DataTableColumn[] {
    return [
      {
        //field: "name",
        field: "fullName",
        header: this.translateService.instant("COMMON_FORMS.NAME"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },

      {
        field: "selected",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("COMMON_FORMS.SELECTION"),
        type: "System.Button",
        icon: "pi pi-pencil",
        cssClass: "button-selected-users-list",
      },

      {
        field: "deleteAction",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("ACTION.DELETE"),
        type: "System.Button",
        icon: "pi pi-trash",
        cssClass: "button-delete-users-list",
      },
    ];
  }
  openForm(
    laboratoryBranchUserData: LaboratoryBranchUsersDataList | undefined
  ) {
    const isNew = !laboratoryBranchUserData;
    let header = "";
    let translateKey = "USERS_MAINTENANCE.FORM.HEADER_EDIT";
    if (isNew) {
      translateKey = "USERS_MAINTENANCE.FORM.HEADER_NEW";
    }
    this.translateService.get(translateKey).subscribe((a: string) => {
      header = a;
    });
    let users;
    if (this.listLaboratoryBranchUsers.length > 0) {
      let userSelected;
      if (!isNew) {
        let copy = [...this.users];
        userSelected = copy.filter(
          (user) => user.id === laboratoryBranchUserData.id
        );
      }
      users = isNew ? this.filteredUsers : userSelected;
    } else {
      users = this.filteredUsers;
    }

    var ref = this.dialogService.open(UserFormComponent, {
      width: "85%",
      height: "90%",
      header: header,
      data: {
        isNew: isNew,
        dataLaboratoryBranchUserDataList: laboratoryBranchUserData,
        laboratoryId: this.laboratoryId,
        users: users,
      },
    });

    ref.onClose.subscribe((laboratoryBranchUser: any) => {
      console.log(laboratoryBranchUser);
      if (laboratoryBranchUser) {
        //TODO improve
        console.log(laboratoryBranchUser);
        this.ngOnInit();
      }
      this.spinner.hide("spinnerUsersList");
    });
  }
  delete(laboratoryBranchUserData: LaboratoryBranchUsersDataList) {
    console.log(laboratoryBranchUserData);
    let trmessage,
      messageAccept = "";
    this.translateService
      .get("COMMONS.ASK_DELETE_RECORD")
      .subscribe((a: string) => {
        trmessage = a;
      });
    this.translateService.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show("spinnerUsersList");
        const suscriptions: Observable<any>[] = [];
        laboratoryBranchUserData.listLaboratoryBranchUsers?.forEach((item) => {
          if (item.id) {
            suscriptions.push(this.laboratoryBranchUserService.delete(item.id));
          }
        });

        forkJoin(suscriptions).subscribe({
          next: (res) => {
            if (
              res &&
              res.length > suscriptions.length - 1 &&
              res[suscriptions.length - 1] !== undefined
            ) {
              this.messagesService.showToast(
                "MESSAGES.SUCCESS.DELETE",
                "MESSAGES.SUCCESS.DELETE",
                "success",
                "HEADER_TITLE.HEADER_TITLE"
              );
              this.spinner.hide("spinnerUsersList");
              this.showTable = false;
              this.ngOnInit();
            }
          },
          error: (err) => {
            console.log(err);
            this.spinner.hide("spinnerUsersList");
          },
        });
      },
      reject: () => {},
    });
  }
}
