import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Observable, forkJoin } from "rxjs";
import {
  LaboratoryBranch,
  LaboratoryBranchUsers,
  LaboratoryBranchUsersDataList,
} from "src/app/models/laboratory-branch.model";
import { LaboratoryBranchUserService } from "src/app/services/laboratory-branch-user.service";
import { LaboratoryBranchService } from "src/app/services/laboratory-branch.service";
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: "app-user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.scss"],
  providers: [ConfirmationService],
})
export class UserFormComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  isNew: boolean = false;
  laboratoryId: string = "";
  dataLaboratoryBranchUserDataList: LaboratoryBranchUsersDataList =
    new LaboratoryBranchUsersDataList();
  listLaboratoryBranch: LaboratoryBranch[] = [];
  selectedLaboratoryBranch: LaboratoryBranch[] = [];
  isLoaded: boolean = false;
  filteredLaboratoryBranch: LaboratoryBranch[] = [];
  user: any;
  isloadedUser: boolean = false;
  isloadedBranch: boolean = false;
  identityUserId: string = "";
  initialList: LaboratoryBranchUsers[] = [];
  users: any[] = [];
  constructor(
    public accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    public dialogConfig: DynamicDialogConfig,
    public dialogService: DialogService,
    public dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private laboratoryBranchService: LaboratoryBranchService,
    private laboratoryBrancUserService: LaboratoryBranchUserService,
    private cd: ChangeDetectorRef
  ) {
    console.log("user-form");
    this.users = this.dialogConfig.data?.users;
    this.isNew = this.dialogConfig.data?.isNew;
    this.laboratoryId = this.dialogConfig.data?.laboratoryId;
    this.dataLaboratoryBranchUserDataList =
      this.dialogConfig.data?.dataLaboratoryBranchUserDataList;
  }
  ngOnInit() {
    console.log("USERS", this.users);
    this.identityUserId = "";
    this.spinner.show("spinnerUserForm");
    this.selectedLaboratoryBranch = [];
    this.initialList = [];
    this.laboratoryBranchService
      .getByLaboratoryId(this.laboratoryId)
      .subscribe({
        next: (items) => {
          console.log("GETLABORATORY", items);
          this.listLaboratoryBranch = [...items];
          if (this.dataLaboratoryBranchUserDataList != null) {
            if (this.dataLaboratoryBranchUserDataList.identityUserId) {
              this.identityUserId =
                this.dataLaboratoryBranchUserDataList.identityUserId;
            }
            if (
              this.dataLaboratoryBranchUserDataList.listLaboratoryBranchUsers
            ) {
              this.initialList =
                this.dataLaboratoryBranchUserDataList.listLaboratoryBranchUsers;
            } else {
              this.initialList = [];
            }
            this.listLaboratoryBranch.forEach((element) => {
              const laboratoryBranch =
                this.dataLaboratoryBranchUserDataList.listLaboratoryBranchUsers?.find(
                  (item) => item.laboratoryBranchId === element.id
                );
              if (laboratoryBranch) {
                this.selectedLaboratoryBranch.push(element);
              }
            });
            let copyList = [...this.listLaboratoryBranch];

            this.filteredLaboratoryBranch = copyList.filter((selected) => {
              return !this.selectedLaboratoryBranch.some(
                (initial) => initial.id === selected.id
              );
            });

            this.filterListBranchOnInit();
          }

          this.isLoaded = true;
          this.spinner.hide("spinnerUserForm");
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("spinnerUserForm");
        },
      });
  }
  ngOnDestroy() {}
  cancel() {
    // this.form.disable();
    this.dialogRef.close();
  }
  back() {
    this.cancel();
  }
  checkIsSelected(event: any) {
    console.log("EVENT SELECT",event);
    console.log("SELECTED LB",this.selectedLaboratoryBranch);
      if(this.selectedLaboratoryBranch.length>0){
        this.isloadedBranch=true;
      }else{
        this.isloadedBranch=false;
      }
  }
  filterLaboratoryBranch(event: AutoCompleteCompleteEvent) {
    console.log(event);
    let filtered: any[] = [];
    let query = event.query;
    if (query != "") {
      for (
        let i = 0;
        i < (this.listLaboratoryBranch as LaboratoryBranch[]).length;
        i++
      ) {
        let laboratoryBranch = (
          this.listLaboratoryBranch as LaboratoryBranch[]
        )[i];
        console.log(laboratoryBranch);
        if (
          laboratoryBranch.name &&
          laboratoryBranch.name.toLowerCase().indexOf(query.toLowerCase()) == 0
        ) {
          console.log(laboratoryBranch);
          filtered.push(laboratoryBranch);
        }
      }

      this.filteredLaboratoryBranch = filtered;
    } else {
      this.filterListBranch();
    }
  }
  filterListBranch() {
    if (this.selectedLaboratoryBranch.length > 0) {
      if (
        this.selectedLaboratoryBranch.length ===
        this.listLaboratoryBranch.length
      ) {
        this.filteredLaboratoryBranch = [];
      } else {
        this.filteredLaboratoryBranch = [...this.listLaboratoryBranch];
        this.selectedLaboratoryBranch.forEach((x) => {
          let index = this.filteredLaboratoryBranch.findIndex(
            (item) => item.name === x.name
          );
          if (index !== -1) {
            this.filteredLaboratoryBranch.splice(index, 1);
          }
          this.filteredLaboratoryBranch = [...this.filteredLaboratoryBranch];
        });
      }
    } else {
      this.filteredLaboratoryBranch = [...this.listLaboratoryBranch];
    }
  }
  filterListBranchOnInit() {
    if (this.selectedLaboratoryBranch.length > 0) {
      if (
        this.selectedLaboratoryBranch.length ===
        this.listLaboratoryBranch.length
      ) {
        this.filteredLaboratoryBranch = [...this.listLaboratoryBranch];
      } else {
        this.selectedLaboratoryBranch.forEach((x) => {
          let index = this.filteredLaboratoryBranch.findIndex(
            (item) => item.name === x.name
          );
          if (index !== -1) {
            this.filteredLaboratoryBranch.splice(index, 1);
          }
          this.filteredLaboratoryBranch = [...this.filteredLaboratoryBranch];
        });
      }
    } else {
      this.filteredLaboratoryBranch = [...this.listLaboratoryBranch];
    }
  }
  create() {
    console.log("selectedLaboratoryBranch", this.selectedLaboratoryBranch);
    console.log("user", this.user);
    // this.selectedLaboratoryBranch=[];
    let newLaboratoryBranchUsers = new LaboratoryBranchUsers();
    if (this.user) {
      newLaboratoryBranchUsers.identityUserId = this.user.id;
      newLaboratoryBranchUsers.name = this.user.firstName;
      newLaboratoryBranchUsers.lastName = this.user.lastName;
      newLaboratoryBranchUsers.email = this.user.email;
    }
    newLaboratoryBranchUsers.laboratoryId = this.laboratoryId;

    // newLaboratoryBranchUsers.email=this.user.e
    if (this.selectedLaboratoryBranch.length > 0) {
      const suscriptions: Observable<any>[] = [];
      this.selectedLaboratoryBranch.forEach((item) => {
        let newLaboratoryBranchUsersOther = new LaboratoryBranchUsers();
        newLaboratoryBranchUsersOther.identityUserId = this.user
          ? this.user.id
          : this.identityUserId;
        newLaboratoryBranchUsersOther.laboratoryId = this.laboratoryId;
        newLaboratoryBranchUsersOther.laboratoryBranchId = item.id;
        newLaboratoryBranchUsersOther.name = this.user
          ? this.user.firstName
          : this.dataLaboratoryBranchUserDataList.name;
        newLaboratoryBranchUsersOther.lastName = this.user
          ? this.user.lastName
          : this.dataLaboratoryBranchUserDataList.lastName;
        newLaboratoryBranchUsersOther.email = this.user
          ? this.user.email
          : this.dataLaboratoryBranchUserDataList.email;
        suscriptions.push(
          this.laboratoryBrancUserService.add(newLaboratoryBranchUsersOther)
        );
      });

      forkJoin(suscriptions).subscribe({
        next: (res) => {
          if (
            res &&
            res.length > suscriptions.length - 1 &&
            res[suscriptions.length - 1] !== undefined
          ) {
            this.messagesService.showToast(
              "MESSAGES.SUCCESS.CREATE",
              "MESSAGES.SUCCESS.CREATE",
              "success",
              "ANALYSIS_TYPE_MAINTENANCE.TITLE"
            );
            this.spinner.hide("spinnerUserForm");
            this.dialogRef.close(newLaboratoryBranchUsers);
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
    } /*else {
      this.spinner.show("spinnerUserForm");
      this.laboratoryBrancUserService.add(newLaboratoryBranchUsers).subscribe({
        next: (item) => {
          this.messagesService.showToast(
            "MESSAGES.SUCCESS.CREATE",
            "MESSAGES.SUCCESS.CREATE",
            "success",
            "ANALYSIS_TYPE_MAINTENANCE.TITLE"
          );
          this.spinner.hide("spinnerUserForm");
          this.dialogRef.close(newLaboratoryBranchUsers);
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("spinnerUserForm");
        },
      });
      }*/
  }
  edit() {
    console.log(
      "dataLaboratoryBranchUserDataList",
      this.dataLaboratoryBranchUserDataList
    );
    let copySelected = [...this.selectedLaboratoryBranch];
    let copyInitial = [...this.initialList];
    let listDelete: any[] = [];
    let listCreate: any[] = [];
    copySelected.forEach((element) => {
      let newList: LaboratoryBranchUsers[] | undefined;
      newList = this.dataLaboratoryBranchUserDataList.listLaboratoryBranchUsers;
      const filteredObjects = newList?.filter((selected) => {
        return !this.selectedLaboratoryBranch.some(
          (element) => element.id === selected.laboratoryBranchId
        );
      });
      if (filteredObjects && filteredObjects.length > 0) {
        listDelete = [...filteredObjects];
      }
    });
    listCreate = copySelected.filter((selected) => {
      return !copyInitial.some(
        (initial) => initial.laboratoryBranchId === selected.id
      );
    });
    if (this.selectedLaboratoryBranch.length === 0) {
      listDelete = [...this.initialList];
    }
    console.log("listCreate", listCreate);
    console.log("listDelete", listDelete);
    console.log(
      "dataLaboratoryBranchUserDataList",
      this.dataLaboratoryBranchUserDataList
    );
    if (listCreate.length > 0) {
      this.selectedLaboratoryBranch = [...listCreate];
      console.log(this.selectedLaboratoryBranch);
      this.create();
    }
    if (listDelete.length > 0) {
      this.delete(listDelete);
    }
  }
  delete(list: any[]) {
    this.spinner.show("spinnerUserForm");
    const suscriptions: Observable<any>[] = [];
    let sendItem: any;
    list.forEach((item) => {
      sendItem = item;
      if (item.id) {
        suscriptions.push(this.laboratoryBrancUserService.delete(item.id));
      }
    });

    forkJoin(suscriptions).subscribe({
      next: (res) => {
        if (
          res &&
          res.length > suscriptions.length - 1 &&
          res[suscriptions.length - 1] !== undefined
        ) {
          this.spinner.hide("spinnerUserForm");
          this.dialogRef.close(sendItem);
        }
      },
      error: (err) => {
        console.log(err);
        this.spinner.hide("spinnerUserForm");
      },
    });
  }
  onSelectedUserChange(eventUser: any) {
    console.log(eventUser);
    this.user = eventUser;
    this.isloadedUser = this.user != null ? true : false;
  }
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
}
